<script setup lang="ts">
import './assets/main.css';
// import { loadUserIfExist } from './system/user';
import { loadLang } from './system/load-lang';
// import { lang } from "~/system/lang";


await loadLang();
// await loadUserIfExist()

// const userStore = useUserStore()
// if(!userStore.hadLogined){
//   console.log('===+++++', userStore.hadLogined)
//   await userAutoLogin()
// }
</script>

<template>
  <NuxtPage />
</template>