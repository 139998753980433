import { default as errorIHP6ewCv2KMeta } from "/home/jayden/projects/greenroom/greenroom/pages/error.vue?macro=true";
import { default as fill_45order6thP6pEYFNMeta } from "/home/jayden/projects/greenroom/greenroom/pages/fill-order.vue?macro=true";
import { default as indexMxH6nOmj43Meta } from "/home/jayden/projects/greenroom/greenroom/pages/index.vue?macro=true";
import { default as logintHyc4sUCMDMeta } from "/home/jayden/projects/greenroom/greenroom/pages/login.vue?macro=true";
import { default as menufU3DnapeRuMeta } from "/home/jayden/projects/greenroom/greenroom/pages/menu.vue?macro=true";
import { default as my_45orderZV9hzuGUPDMeta } from "/home/jayden/projects/greenroom/greenroom/pages/my-order.vue?macro=true";
import { default as _91productId_933VmxMS1L0iMeta } from "/home/jayden/projects/greenroom/greenroom/pages/product/[productId].vue?macro=true";
import { default as productsTXJYzD3u8NMeta } from "/home/jayden/projects/greenroom/greenroom/pages/products.vue?macro=true";
import { default as refresh_45tokengtMbCH9949Meta } from "/home/jayden/projects/greenroom/greenroom/pages/refresh-token.vue?macro=true";
import { default as temp6Z6BooGsJdMeta } from "/home/jayden/projects/greenroom/greenroom/pages/temp.vue?macro=true";
export default [
  {
    name: errorIHP6ewCv2KMeta?.name ?? "error",
    path: errorIHP6ewCv2KMeta?.path ?? "/error",
    meta: errorIHP6ewCv2KMeta || {},
    alias: errorIHP6ewCv2KMeta?.alias || [],
    redirect: errorIHP6ewCv2KMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/error.vue").then(m => m.default || m)
  },
  {
    name: fill_45order6thP6pEYFNMeta?.name ?? "fill-order",
    path: fill_45order6thP6pEYFNMeta?.path ?? "/fill-order",
    meta: fill_45order6thP6pEYFNMeta || {},
    alias: fill_45order6thP6pEYFNMeta?.alias || [],
    redirect: fill_45order6thP6pEYFNMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/fill-order.vue").then(m => m.default || m)
  },
  {
    name: indexMxH6nOmj43Meta?.name ?? "index",
    path: indexMxH6nOmj43Meta?.path ?? "/",
    meta: indexMxH6nOmj43Meta || {},
    alias: indexMxH6nOmj43Meta?.alias || [],
    redirect: indexMxH6nOmj43Meta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logintHyc4sUCMDMeta?.name ?? "login",
    path: logintHyc4sUCMDMeta?.path ?? "/login",
    meta: logintHyc4sUCMDMeta || {},
    alias: logintHyc4sUCMDMeta?.alias || [],
    redirect: logintHyc4sUCMDMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/login.vue").then(m => m.default || m)
  },
  {
    name: menufU3DnapeRuMeta?.name ?? "menu",
    path: menufU3DnapeRuMeta?.path ?? "/menu",
    meta: menufU3DnapeRuMeta || {},
    alias: menufU3DnapeRuMeta?.alias || [],
    redirect: menufU3DnapeRuMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: my_45orderZV9hzuGUPDMeta?.name ?? "my-order",
    path: my_45orderZV9hzuGUPDMeta?.path ?? "/my-order",
    meta: my_45orderZV9hzuGUPDMeta || {},
    alias: my_45orderZV9hzuGUPDMeta?.alias || [],
    redirect: my_45orderZV9hzuGUPDMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/my-order.vue").then(m => m.default || m)
  },
  {
    name: _91productId_933VmxMS1L0iMeta?.name ?? "product-productId",
    path: _91productId_933VmxMS1L0iMeta?.path ?? "/product/:productId()",
    meta: _91productId_933VmxMS1L0iMeta || {},
    alias: _91productId_933VmxMS1L0iMeta?.alias || [],
    redirect: _91productId_933VmxMS1L0iMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/product/[productId].vue").then(m => m.default || m)
  },
  {
    name: productsTXJYzD3u8NMeta?.name ?? "products",
    path: productsTXJYzD3u8NMeta?.path ?? "/products",
    meta: productsTXJYzD3u8NMeta || {},
    alias: productsTXJYzD3u8NMeta?.alias || [],
    redirect: productsTXJYzD3u8NMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/products.vue").then(m => m.default || m)
  },
  {
    name: refresh_45tokengtMbCH9949Meta?.name ?? "refresh-token",
    path: refresh_45tokengtMbCH9949Meta?.path ?? "/refresh-token",
    meta: refresh_45tokengtMbCH9949Meta || {},
    alias: refresh_45tokengtMbCH9949Meta?.alias || [],
    redirect: refresh_45tokengtMbCH9949Meta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/refresh-token.vue").then(m => m.default || m)
  },
  {
    name: temp6Z6BooGsJdMeta?.name ?? "temp",
    path: temp6Z6BooGsJdMeta?.path ?? "/temp",
    meta: temp6Z6BooGsJdMeta || {},
    alias: temp6Z6BooGsJdMeta?.alias || [],
    redirect: temp6Z6BooGsJdMeta?.redirect,
    component: () => import("/home/jayden/projects/greenroom/greenroom/pages/temp.vue").then(m => m.default || m)
  }
]