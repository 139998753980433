import { initLang } from './lang'
import { serviceUrl } from "~/system/config";

// function getLangPreference():[number, string]{
//     let langIndex = 0

//     const userSelectedLang = useCookie('lang')
//     if(userSelectedLang.value){
//         langIndex = parseInt(userSelectedLang.value)
//     }else if (navigator.languages.length > 0) {
//         const preferenceLan = navigator.languages[0].substring(0, 2)
//         const langMap = new Map<string, number>([
//             ['vn', 0], ['vi', 0], ['en', 1], ['zh', 2], ['cn', 2]
//         ])
//         const index = langMap.get(preferenceLan)
//         if (index !== undefined) {
//             langIndex = index
//         }
//     }

//     return [langIndex, ['vn', 'en', 'cn'][langIndex]]
// }

function getLangPreference(): [number, string] {
    let langIndex = 0

    const userSelectedLang = useCookie('lang')
    if (userSelectedLang.value) {
        langIndex = parseInt(userSelectedLang.value)
    } else {
        let preferenceLan = ''
        if (process.server) {
            const headers = useRequestHeaders(['accept-language'])
            if (headers['accept-language']) {
                preferenceLan = headers['accept-language'].substring(0, 2)
            }
        } else if (navigator.languages.length > 0) {
            preferenceLan = navigator.languages[0].substring(0, 2)
        }

        const langMap = new Map<string, number>([
            ['vn', 0], ['vi', 0], ['en', 1], ['zh', 2], ['cn', 2]
        ])
        const index = langMap.get(preferenceLan)
        if (index !== undefined) {
            langIndex = index
        }

        userSelectedLang.value = langIndex.toString()
    }

    return [langIndex, ['vn', 'en', 'cn'][langIndex]]
}

export async function loadLang() {
    const [_, name] = getLangPreference()
    const url = `${serviceUrl}/lang/${name}.json`
    // const url = `http://localhost:3000/lang/${name}.json`
    // const url = `/lang/${name}.json`
    const response = await fetch(url)
    const langData = await response.json()
    initLang(langData)
}