import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoIconBack, LazySvgoIconClose, LazySvgoIconDecrease, LazySvgoIconIncrease, LazySvgoIconLanguage, LazySvgoIconShoppingCart, LazySvgoIconTrash } from '#components'
const lazyGlobalComponents = [
  ["SvgoIconBack", LazySvgoIconBack],
["SvgoIconClose", LazySvgoIconClose],
["SvgoIconDecrease", LazySvgoIconDecrease],
["SvgoIconIncrease", LazySvgoIconIncrease],
["SvgoIconLanguage", LazySvgoIconLanguage],
["SvgoIconShoppingCart", LazySvgoIconShoppingCart],
["SvgoIconTrash", LazySvgoIconTrash],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
